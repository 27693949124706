import React from "react";
import { MDBBtn } from "mdb-react-ui-kit";
import "./Hero.css";

const Hero = () => {
  const copyIPToClipboard = () => {
    const ip = "play.skyboundmc.eu";
    navigator.clipboard
      .writeText(ip)
      .then(() => {
        alert(`IP Address "${ip}" copied to clipboard!`);
      })
      .catch((error) => {
        console.error("Failed to copy IP to clipboard: ", error);
        alert("Failed to copy IP to clipboard. Please try again.");
      });
  };

  return (
    <div
      className="p-5 text-center bg-image hero_bg"
      id="top"
      style={{
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "720px",
      }}
    >
      <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-white">
            <h1 className="mb-2 fw-normal">
              Welcome to <br />
              <b>SkyBound Network</b>
            </h1>
            <h4 className="mb-5 lead">
              Where boundless adventure awaits you in the skies!
            </h4>
            <MDBBtn
              color="danger"
              rippleColor="white"
              tag="a"
              outline
              size="lg"
              onClick={copyIPToClipboard}
            >
              Begin Your Journey <br /> (copy ip)
            </MDBBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBTypography,
} from "mdb-react-ui-kit";

import './Vote.css'

const Vote = () => {
  return (
    <MDBContainer className="text-center vote_bg p-5" fluid id="vote">
      <MDBContainer className="p-4">
        <MDBTypography tag="h2" className="display-5 text-white">
          Vote and get{" "}
          <span className="fw-bold text-decoration-underline text-danger">GREAT</span>{" "}
          rewards
        </MDBTypography>
        <p className="text-white fs-5">It also helps us!</p>
        <MDBRow className="justify-content-center mt-4">
          <MDBCol md="6" lg="4" className="mb-4 p-2">
            <MDBCard className="glass text-white">
              <MDBCardBody>
                <MDBCardTitle className="text-center">
                  Best Minecraft Servers
                </MDBCardTitle>
                <MDBCardText>
                  <p>Vote for us and get x1 vote key and ingame currency!</p>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="btn btn-outline btn-danger"
                        href="https://best-minecraft-servers.co/server-skybound.22875/vote"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vote
                      </a>
                    </li>
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6" lg="4" className="mb-4 p-2">
            <MDBCard className="glass text-white">
              <MDBCardBody>
                <MDBCardTitle className="text-center">TopG</MDBCardTitle>
                <MDBCardText>
                  <p>Vote for us and get x1 vote key and ingame currency!</p>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="btn btn-outline btn-danger"
                        href="https://topg.org/minecraft-servers/server-662693"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vote
                      </a>
                    </li>
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6" lg="4" className="mb-4 p-2">
            <MDBCard className="glass text-white">
              <MDBCardBody>
                <MDBCardTitle className="text-center">
                  MinecraftServers
                </MDBCardTitle>
                <MDBCardText>
                  <p>Vote for us and get x1 vote key and ingame currency!</p>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="btn btn-outline btn-danger"
                        href="https://minecraftservers.org/vote/661076"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vote
                      </a>
                    </li>
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6" lg="4" className="mb-4 p-2">
            <MDBCard className="glass text-white">
              <MDBCardBody>
                <MDBCardTitle className="text-center">
                  Minecraft-mp
                </MDBCardTitle>
                <MDBCardText>
                  <p>Vote for us and get x1 vote key and ingame currency!</p>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="btn btn-outline btn-danger"
                        href="https://minecraft-mp.com/server/330830/vote/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vote
                      </a>
                    </li>
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="6" lg="4" className="mb-4 p-2">
            <MDBCard className="glass text-white">
              <MDBCardBody>
                <MDBCardTitle className="text-center">
                  Minecraft Buzz
                </MDBCardTitle>
                <MDBCardText>
                  <p>Vote for us and get x1 vote key and ingame currency!</p>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="btn btn-outline btn-danger"
                        href="https://minecraft.buzz/vote/10438"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vote
                      </a>
                    </li>
                  </ul>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  );
};

export default Vote;

import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBTypography,
} from "mdb-react-ui-kit";

import logo from '../../assets/SkyboundLogo.png'

const About = () => {
  return (
    <MDBContainer fluid>
      <MDBRow className="justify-content-center align-items-center my-5 d-flex">
        <MDBCol className="mb-4 col-md-4">
                <MDBTypography tag="h3" className="mb-3 fw-bold">About SkyBound</MDBTypography>
                <p>
                  Welcome to SkyBound, your ultimate destination for skyblock
                  adventure in Minecraft!
                </p>
                <p>
                  At SkyBound, we offer a unique and immersive gameplay
                  experience, where players can build, explore, and thrive in a
                  skybound world filled with endless possibilities.
                </p>
                <p>Our server features:</p>
                <ul>
                  <li>Custom Menus</li>
                  <li>Crates</li>
                  <li>Island Upgrades</li>
                  <li>Seasons and Dynamic Weather</li>
                  <li>Custom Mines and Lost World</li>
                  <li>Minions</li>
                  <li>Bitcoin</li>
                  <li>Daily Rewards and more!</li>
                </ul>
                <p>
                  Join us today and embark on a skyblock adventure like never
                  before!
                </p>
        </MDBCol>
        <MDBCol className="mb-4 col-md-4 justify-content-center align-items-center d-flex">
            <img src={logo} alt="" className="img-fluid w-50" />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default About;

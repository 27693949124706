import React from 'react'
import { MDBContainer, MDBFooter } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <MDBFooter
      className="text-center text-white bg-danger"
    >

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024 Copyright <br /> <a className="text-white" href="https://Skyboundmc.eu"> SkyBound Network</a>
      </div>
    </MDBFooter>
  );
}

export default Footer
import { MDBContainer, MDBTypography } from 'mdb-react-ui-kit';
import React from 'react'
import './ServerStatus.css'
import bg_skyblock from '../../assets/skyblock/skyblock.png'
import bg_adventure from "../../assets/adventure/Adventure.jpg";
import bg_towny from "../../assets/towny/Towny.png";

const ServerStatus = () => {

    const copyIPToClipboard = () => {
      const ip = "play.skyboundmc.eu";
      navigator.clipboard
        .writeText(ip)
        .then(() => {
          alert(`IP Address "${ip}" copied to clipboard!`);
        })
        .catch((error) => {
          console.error("Failed to copy IP to clipboard: ", error);
          alert("Failed to copy IP to clipboard. Please try again.");
        });
    };


  return (
    <MDBContainer
      className="d-flex bg-danger w-100 align-items-center"
      style={{
        height: "auto",
      }}
      id="servers"
      fluid
    >
      <div className="justify-content-center w-100 text-center text-light">
        <h2 className="display-5 mt-4">
          Start your adventure with <br />{" "}
          <span className="fw-bold">SkyBound</span>
        </h2>
        <p className="lead">Explore our servers</p>
        <hr className="hr hr-blurry w-25 m-auto" />

        <div className="d-flex justify-content-center align-items-center flex-column">
          <p className='mt-3'>Click to copy:</p>
          <MDBTypography
            tag="p"
            className="lead"
            onClick={copyIPToClipboard}
            style={{ cursor: "pointer" }}
          >
            Play.SkyBoundMC.eu
          </MDBTypography>
        </div>

        <div className="my-5 d-flex flex-row justify-content-center server_list">
          {/* 
            Skyblock server
             */}
          <div className="server_wrapper my-4 server skyblock justify-content-center align-items-center d-flex flex-column">
            <div className="bg-image h-100 server_card">
              <img
                src={bg_skyblock}
                className="w-100 h-100"
                alt="Skyblock backgrund"
              />
              <div className="mask mask-overlay">
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <MDBTypography tag="h3" className="fw-bold">
                    SB Skyblock
                  </MDBTypography>
                  <MDBTypography tag="p" className="lead w-75">
                    Bitcoins, Minions, Fun + More!
                  </MDBTypography>
                </div>
              </div>
            </div>
          </div>
          {/* 
            Adventure server
             */}
          <div className="server_wrapper my-4 server skyblock justify-content-center align-items-center d-flex flex-column">
            <div className="bg-image h-100 server_card">
              <img
                src={bg_adventure}
                className="w-100 h-100"
                alt="Skyblock backgrund"
              />
              <div className="mask mask-overlay">
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <MDBTypography tag="h3" className="fw-bold">
                    SB Adventure
                  </MDBTypography>
                  <MDBTypography tag="p" className="lead w-75">
                    COMING SOON
                  </MDBTypography>
                </div>
              </div>
            </div>
          </div>
          {/* 
            Towny server
             */}
          <div className="server_wrapper my-4 server skyblock justify-content-center align-items-center d-flex flex-column">
            <div className="bg-image h-100 server_card">
              <img
                src={bg_towny}
                className="w-100 h-100"
                alt="Towny backgrund"
              />
              <div className="mask mask-overlay">
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <MDBTypography tag="h3" className="fw-bold">
                    SB Towny
                  </MDBTypography>
                  <MDBTypography tag="p" className="lead w-75">
                    Our most popular server
                  </MDBTypography>
                  <MDBTypography tag="p" className="lead w-75">
                    Custom menus, Harder mobs, Balanced
                  </MDBTypography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MDBContainer>
  );
}

export default ServerStatus
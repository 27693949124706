import React from 'react';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import Navbar from './container/navbar/Navbar';
import Hero from './container/hero/Hero';
import ServerStatus from './container/serverStatus/ServerStatus';
import Vote from './container/vote/Vote'
import About from './container/about/About';
import Footer from './footer/Footer';
import Socials from './container/socials/Socials';

function App() {
  return (
    <div>
      <Navbar/>
      <Hero />
      <ServerStatus />
      <Socials />
      <Vote />
      <About />
      <Footer />
    </div>
  );
}

export default App;

import { MDBBtn, MDBContainer, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Discord_logo from '../../assets/Discord-logo.png'

const discordLink = () => {
    window.open("https://discord.skyboundmc.eu", "_blank");
}

const Socials = () => {
  return (
    <MDBContainer className="d-flex flex-row my-5 w-100">
      <div className="col-md-7 m-auto">
        <MDBTypography tag="h2" className="my-3">
          🚀 Join Our Discord Community! 🚀
        </MDBTypography>
        <p>
          Looking for a place to connect with fellow adventurers, stay updated
          on server events, and engage with the SkyBound community? Look no
          further! Our Discord group is the hub for all things SkyBound Network.
        </p>
        <ul>
          <li>
            🎮 Connect with Players: Meet like-minded gamers, team up for epic
            quests, and make new friends as you explore the skies together.
          </li>
          <br />
          <li>
            📢 Stay Informed: Receive real-time updates on server maintenance,
            events, and special promotions directly from our staff.
          </li>
          <br />
          <li>
            💬 Engage and Chat: Share tips, strategies, and hilarious moments
            with other players in our active chat channels.
          </li>
          <br />
          <li>
            🔧 Get Support: Need assistance with gameplay, plugins, or
            server-related issues? Our helpful staff and community members are
            here to help!
          </li>
        </ul>
        <MDBBtn className='my-2' onClick={discordLink}>Join Now</MDBBtn>
      </div>
      <div className="col-md-2 flex-row d-flex">
        <img
          src={Discord_logo}
          alt=""
          className="img-fluid ms-5 my-auto"
          onClick={discordLink}
          style={{ cursor: "pointer", height: "200px" }}
        />
      </div>
    </MDBContainer>
  );
}

export default Socials